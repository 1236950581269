import React, { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { isBefore, isToday, isYesterday, set, format, isAfter, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { twMerge } from 'tailwind-merge';
import { INITIALCHANNELFETCHCOUNT, TV_DATE_RANGES } from 'tvm-config';
import { LoadingIndicator } from '@components/atoms/LoadingIndicator/LoadingIndicator';
import { Title } from '@components/atoms/Title';
import { IconNext } from '@src/assets/icon-next';
import { IconPrev } from '@src/assets/icon-prev';
import { reloadEveryPlacedAdslot } from '@src/lib/adition/adFarm';
import { useTvGuideStore } from '@src/stores/tvguide';
import { useScrollSync } from '@src/utils/useScrollSync';
import { TvChannelDay, TvChannelShowtime } from '@lib/graphql/generated';
import { IconArrowCollapse } from '@assets/icon-arrow-collapse';
import { ColumnChannelProgram, ChannelBase } from '.';

const FeaturedProgramEntry = dynamic(() => import('./FeaturedProgramEntry').then((mod) => mod['FeaturedProgramEntry']));

const setCurrentDateRange = () => {
  const now = toZonedTime(new Date(), 'Europe/Vienna');
  if (
    isAfter(
      set(new Date(), {
        hours: TV_DATE_RANGES[0].firstHour,
        minutes: TV_DATE_RANGES[0].firstMinute || 0,
        seconds: 0,
        milliseconds: 0,
      }),
      now,
    )
  ) {
    return 0;
  }
  const idx =
    TV_DATE_RANGES.findIndex((e) => {
      return isAfter(
        set(new Date(), { hours: e.firstHour, minutes: e.firstMinute || 0, seconds: 0, milliseconds: 0 }),
        now,
      );
    }) - 1;
  return idx > -1 ? idx : TV_DATE_RANGES.length - 1;
};

export interface ColumnViewProps {
  channelEntries: TvChannelDay[];
  activeDay: Date;
  fetching?: boolean;
  currentPage?: number;
  hasMorePages?: boolean;
  onFetchMore?: () => void;
}

export const ColumnView = ({ channelEntries, activeDay, fetching, hasMorePages, onFetchMore, currentPage }) => {
  const now = useTvGuideStore((state) => state.tvCurrentTime);
  const dragging = useTvGuideStore((state) => state.dragging);
  const setDragging = useTvGuideStore((state) => state.setDragging);
  const setGuideScroll = useTvGuideStore((state) => state.setGuideScroll);
  const lastActiveDay = useRef<Date>(activeDay);
  const showtimesScrollElement = useRef<HTMLDivElement>(null);
  const channelsScrollElement = useRef(null);
  const syncedScroll = useScrollSync(channelsScrollElement, showtimesScrollElement);

  const documentRef = useRef<Document>(null);
  const dragPositions = useRef({
    left: null,
    top: null,
    x: null,
    y: null,
  });

  useEffect(() => {
    documentRef.current = document;
  }, []);

  const [activeAreaIndex, setActiveAreaIndex] = useState(() => {
    if (
      isYesterday(activeDay) &&
      isBefore(now, set(new Date(), { hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }))
    ) {
      return TV_DATE_RANGES.length - 1;
    } else if (isToday(activeDay)) {
      return setCurrentDateRange();
    } else {
      return null;
    }
  });

  const changeScrollPosition = (count: number) => {
    const scrollElements = channelsScrollElement?.current?.childNodes;

    if (!scrollElements || scrollElements.length < 1 || !showtimesScrollElement.current) return;

    const elementWidth = scrollElements[0]?.offsetWidth + 3;
    const containerWidth = showtimesScrollElement.current.offsetWidth;
    const elementCountVisible = Math.floor(containerWidth / elementWidth) || 1;

    const currentScrollPosition = showtimesScrollElement.current?.scrollLeft;
    syncedScroll(currentScrollPosition + count * elementCountVisible * elementWidth, true);

    setGuideScroll(0);
  };

  useTvGuideStore.subscribe(
    (state) => state.guideScroll,
    (scrollDirectionNumber) => {
      if (scrollDirectionNumber) changeScrollPosition(scrollDirectionNumber);
    },
  );

  useEffect(() => {
    if (!isSameDay(lastActiveDay.current, activeDay)) {
      setActiveAreaIndex(isToday(activeDay) ? setCurrentDateRange() : null);
      syncedScroll(0, true);
    }
    lastActiveDay.current = activeDay;
  }, [activeDay, syncedScroll]);

  function mouseMoveListener(evt) {
    const dx = evt.clientX - dragPositions.current.x;
    const dy = evt.clientY - dragPositions.current.y;
    if (!dragging && (Math.abs(dx) > 5 || Math.abs(dy) > 5)) {
      setDragging(true);
    }
    if (showtimesScrollElement.current) {
      showtimesScrollElement.current.scrollLeft = dragPositions.current.left - dx;
      document.documentElement.scrollTop = dragPositions.current.top - dy;
    }
  }

  function removeListeners() {
    documentRef.current.removeEventListener('mousemove', mouseMoveListener);
    documentRef.current.removeEventListener('mouseup', mouseUpListener);
  }

  function mouseUpListener() {
    setTimeout(() => {
      removeListeners();
      setDragging(false);

      if (document?.documentElement) {
        document.documentElement.style.scrollBehavior = 'smooth';
      }
    }, 5);
  }

  function handleMouseDown(evt) {
    documentRef.current.addEventListener('mousemove', mouseMoveListener);
    documentRef.current.addEventListener('mouseup', mouseUpListener);
    if (showtimesScrollElement) {
      document.documentElement.style.scrollBehavior = 'auto';
    }
    dragPositions.current = {
      left: showtimesScrollElement.current.scrollLeft,
      top: document.documentElement.scrollTop,
      x: evt.clientX,
      y: evt.clientY,
    };
  }

  function createRangeTimesTitle(area, nextArea) {
    const from = set(new Date(), {
      hours: area.firstHour,
      minutes: area.firstMinute || 0,
    });
    const to = set(new Date(), {
      hours: nextArea.firstHour,
      minutes: nextArea.firstMinute || 0,
    });
    return format(from, 'HH:mm') + ' - ' + format(to, 'HH:mm');
  }

  function getCurrentProgram(channelEntry: TvChannelDay): TvChannelShowtime {
    return channelEntry?.showtimes?.find(
      (showtime) => isAfter(now, new Date(showtime.start)) && isBefore(now, new Date(showtime.stop)),
    );
  }

  if (!channelEntries) return null;

  return (
    <>
      <div
        className="sticky top-24 z-10 mt-8 grid h-[76px] w-full select-none grid-flow-col overflow-x-auto bg-black pb-1 will-change-scroll scrollbar-none md:h-[116px]"
        ref={channelsScrollElement}
      >
        <button
          className="sticky left-4 top-4 z-10 -mr-12 grid size-11 flex-none place-items-center rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-primary hover:text-primary md:left-8 md:top-9 md:size-12"
          onClick={() => {
            reloadEveryPlacedAdslot();
            setGuideScroll(-1);
          }}
        >
          <IconPrev
            classProps={{
              root: 'size-6',
            }}
          />
        </button>
        {channelEntries.map((entry) => (
          <ChannelBase
            columnView={true}
            channel={entry?.channel}
            bookmarked={!!entry?.channel?.favorite?.id}
            key={entry?.channel?.id}
            classProps={{ root: 'mr-1' }}
          />
        ))}
        <InView
          className="relative w-48 md:w-52"
          onChange={(inView) => {
            if (inView && hasMorePages) {
              onFetchMore();
            }
          }}
        >
          <LoadingIndicator visible={fetching} classProps={{ root: 'min-h-0 h-full' }} />
        </InView>
        <button
          className="sticky right-4 top-4 z-10 -ml-12 grid size-11 flex-none place-items-center rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-primary hover:text-primary md:left-8 md:top-9 md:size-12"
          onClick={() => {
            reloadEveryPlacedAdslot();
            setGuideScroll(1);
          }}
        >
          <IconNext
            classProps={{
              root: 'size-6',
            }}
          />
        </button>
      </div>
      <div
        className={twMerge(
          'w-full select-none overflow-x-auto pb-8 will-change-scroll scrollbar-none',
          dragging && 'cursor-move',
        )}
        ref={showtimesScrollElement}
        onMouseDown={handleMouseDown}
      >
        <div className="mb-8 grid w-full select-none grid-flow-col gap-1">
          {channelEntries.map((entry, index) => (
            <div className="w-48 md:w-52" key={entry?.channel?.id}>
              {(isToday(activeDay) ||
                (isYesterday(activeDay) &&
                  isBefore(
                    now,
                    set(new Date(), {
                      hours: TV_DATE_RANGES[0].firstHour,
                      minutes: TV_DATE_RANGES[0].firstMinute || 0,
                      seconds: 0,
                      milliseconds: 0,
                    }),
                  ))) && (
                <FeaturedProgramEntry
                  classProps={{ root: 'mt-1' }}
                  showtime={getCurrentProgram(entry)}
                  channel={entry?.channel}
                  type="current"
                  isFirst={index < 3}
                />
              )}
            </div>
          ))}
          {hasMorePages && <div className="w-48 md:w-52" />}
        </div>
        <div style={{ width: channelsScrollElement.current?.scrollWidth }}></div>
        {TV_DATE_RANGES.map((area, index) => (
          <React.Fragment key={index}>
            <div
              className={twMerge(
                'sticky left-0 top-0 inline-flex h-18 min-w-full cursor-pointer scroll-mt-56 items-center justify-center gap-4 border-t border-gray-650/65 text-center',
                index === TV_DATE_RANGES.length - 1 && index !== activeAreaIndex && 'border-b',
              )}
              id={`area-${index}-${area.name}`}
              onClick={(evt) => {
                if (!dragging) {
                  evt.stopPropagation();
                  setActiveAreaIndex(index === activeAreaIndex ? null : index);
                  if (index !== activeAreaIndex) {
                    setTimeout(() => {
                      const el = evt.target as HTMLDivElement;
                      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      removeListeners();
                    });
                  }
                }
              }}
            >
              <div className="pointer-events-none">
                <IconArrowCollapse open={index === activeAreaIndex} />
              </div>
              <Title
                classProps={{
                  heading:
                    'py-4 text-base font-bold uppercase lg:text-lg tracking-widest font-proxima-nova pointer-events-none',
                }}
                level={2}
              >
                {area.name}
              </Title>
              <div className="pointer-events-none text-sm uppercase tracking-2px">
                {createRangeTimesTitle(area, TV_DATE_RANGES[(index + 1) % TV_DATE_RANGES.length])}
              </div>
              <div className="pointer-events-none">
                <IconArrowCollapse open={index === activeAreaIndex} />
              </div>
            </div>
            <LoadingIndicator
              visible={index === activeAreaIndex && fetching && currentPage === null}
              classProps={{ root: 'h-16 w-48 mx-auto min-h-0' }}
            />

            <div className={twMerge('relative', index === activeAreaIndex ? 'mb-8' : 'border-b border-gray-650/65')}>
              <div className="grid grid-flow-col gap-1">
                {channelEntries.map((entry, entryIndex) => (
                  <div className="h-full w-48 md:w-52" key={entry?.channel?.id}>
                    <div className="size-full">
                      <ColumnChannelProgram
                        active={index === activeAreaIndex}
                        activeDay={activeDay}
                        initialLoad={entryIndex <= INITIALCHANNELFETCHCOUNT}
                        rangeFrom={{ hours: area.firstHour, minutes: area.firstMinute || 0 }}
                        rangeTo={{
                          hours: TV_DATE_RANGES[(index + 1) % TV_DATE_RANGES.length].firstHour,
                          minutes: TV_DATE_RANGES[(index + 1) % TV_DATE_RANGES.length].firstMinute || 0,
                        }}
                        channelEntry={entry}
                        containsPrimeTime={area.primeTime}
                        currentTime={now}
                      />
                    </div>
                  </div>
                ))}
                {hasMorePages && index === activeAreaIndex && <div className="w-48 md:w-52" />}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
