import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '@components/atoms/Spinner/Spinner';

export interface LoadingIndicatorProps extends PropsWithClassProps {
  visible: boolean;
}

export const LoadingIndicator = ({ visible, classProps }: LoadingIndicatorProps) => {
  return (
    visible && (
      <div className={twMerge('grid min-h-[30vh] w-full place-items-center', classProps?.root)}>
        <Spinner />
      </div>
    )
  );
};
